import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-play-button',
  templateUrl: './play-button.component.html',
  styleUrls: ['./play-button.component.scss']
})
export class PlayButtonComponent implements OnInit {

  playing = false;

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this.playing = !this.playing;
  }

}
